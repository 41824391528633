<template>
    <Login/>
    <Registration/>
    <ResetPassword/>
    <Comfirm/>
    <openProject/>
    <message/>
    <downloadLinks_bill/>
    <downloadLinks_project/>
</template>


<script>
export default{
    name: 'Popaps',
    data(){
        return {

        }
    },
}
</script>