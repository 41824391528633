<template>
    <div class="wrap">

        <h1>Datenschutzerklärung</h1>
        <h3>Verantwortlicher</h3>
        <p>
            Viktor Filimonow<br>
            HONORAR.online<br>
            c/o FAAR.studio<br>
            Plinganserstraße 150<br>
            81369 München<br>  
            E-Mail: mail@honorar.online<br> 
        </p>
 
        <div>
                #### Allgemeine Hinweise
                Der Schutz Ihrer personenbezogenen Daten ist uns ein wichtiges Anliegen. Wir verarbeiten Ihre Daten gemäß den gesetzlichen Datenschutzvorschriften, insbesondere der EU-Datenschutz-Grundverordnung (DSGVO) und des Bundesdatenschutzgesetzes (BDSG). In dieser Datenschutzerklärung informieren wir Sie darüber, welche Daten wir erheben, wie wir sie verwenden und welche Rechte Sie in Bezug auf Ihre Daten haben.

                ---

                ### 1. Verantwortliche Stelle und Kontakt für Datenschutz
                Die verantwortliche Stelle für die Verarbeitung Ihrer Daten ist:

                Viktor Filimonow
                c/o FAAR.studio 
                Plinganserstraße 150  
                81369 München  
                E-Mail: mail@honorar.online  

                Bei Fragen zum Datenschutz können Sie sich jederzeit an uns wenden.

                ---

                ### 2. Datenverarbeitung beim Besuch unserer Webseite

                #### a) Hosting
                Unsere Webseite wird bei netcup.de gehostet. Die Server befinden sich innerhalb der Europäischen Union.

                #### b) Verschlüsselte Datenübertragung
                Die Datenübertragung auf unserer Webseite erfolgt mittels SSL/TLS-Verschlüsselung, um Ihre Daten vor unbefugtem Zugriff zu schützen.

                #### c) Cookies
                Wir verwenden Cookies, um die Benutzerfreundlichkeit unserer Webseite zu verbessern. Es werden insbesondere Cookies verwendet, um die von Ihnen getätigten Eingaben lokal zu speichern. Tracking-Cookies werden derzeit **nicht** eingesetzt. Zukünftig kann jedoch ein Tracking-Tool eingesetzt werden. Dieser Abschnitt wird entsprechend angepasst.

                ---

                ### 3. Erfassung und Verarbeitung personenbezogener Daten

                #### a) Daten bei Registrierung und Eingabe
                Sie können Daten auf unserer Webseite eingeben, ohne ein Benutzerkonto zu erstellen. Wenn Sie dabei personenbezogene Daten verwenden, werden diese gespeichert. 
                Falls Sie ein Benutzerkonto erstellen, können folgende personenbezogene Angaben für die Registrierung erforderlich sein und werden verarbeitet:
                - Name
                - Adresse
                - Telefonnummer
                - E-Mail-Adresse


                #### b) Speicherfristen
                - Daten von **unregistrierten Nutzern** werden für **30 Tage** gespeichert.
                - Daten von **registrierten Nutzern** bleiben gespeichert, bis diese das Konto oder die entsprechenden Daten selbst löschen.

                #### c) Rechtsgrundlage
                Die Verarbeitung Ihrer Daten erfolgt auf Grundlage Ihrer **Einwilligung** (Art. 6 Abs. 1 lit. a DSGVO) sowie zur **Vertragserfüllung** (Art. 6 Abs. 1 lit. b DSGVO) bei registrierten Nutzern.

                ---

                ### 4. Weitergabe von Daten
                Ihre Daten werden grundsätzlich nicht an Dritte weitergegeben. Unsere Seite befindet sich im Aufbau und wird täglich aktualisiert. Hierzu kann die Datenbank und Ihre Daten durch Dritte auch aus nicht EU-Ländern eingesehen werden. Der Personenkreis ist derzeit auf eine Person beschränkt. Wir haben entsprechende Vereinbarungen getroffen, um den Schutz Ihrer Daten sicherzustellen. Die Daten werden ausschließlich als Datensatz verarbeitet und nicht als Einzeleinträge gesichtet oder verarbeitet.

                ---

                ### 5. Nutzung der Daten zu Marketingzwecken
                Ihre Kontaktdaten können von uns für Marketingzwecke genutzt werden, beispielsweise für:
                - Zusendung von Newslettern
                - Telefonische Kontaktaufnahme
                - Zusendung von Informationen zu unseren Dienstleistungen per Post oder E-Mail

                Sie können der Nutzung Ihrer Daten für Marketingzwecke jederzeit widersprechen. Kontaktieren Sie uns hierzu unter: datenschutz@webseite.de

                ---

                ### 6. Ihre Rechte
                Als Betroffener haben Sie gemäß DSGVO folgende Rechte:

                - **Recht auf Auskunft** über Ihre gespeicherten Daten (Art. 15 DSGVO)
                - **Recht auf Berichtigung** unrichtiger oder unvollständiger Daten (Art. 16 DSGVO)
                - **Recht auf Löschung** Ihrer Daten (Art. 17 DSGVO)
                - **Recht auf Einschränkung der Verarbeitung** (Art. 18 DSGVO)
                - **Recht auf Datenübertragbarkeit** (Art. 20 DSGVO)
                - **Recht auf Widerspruch** gegen die Verarbeitung Ihrer Daten (Art. 21 DSGVO)
                - **Recht auf Widerruf** Ihrer Einwilligung (Art. 7 Abs. 3 DSGVO)

                Zur Wahrnehmung dieser Rechte können Sie uns unter der oben genannten Adresse kontaktieren.

                ---

                ### 7. Beschwerderecht bei einer Aufsichtsbehörde
                Wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten gegen die DSGVO verstößt, haben Sie das Recht, eine Beschwerde bei einer Aufsichtsbehörde einzureichen.

                Die zuständige Aufsichtsbehörde richtet sich nach Ihrem Wohnort. Eine Liste der Aufsichtsbehörden in der EU finden Sie unter folgendem Link: [https://www.edpb.europa.eu/about-edpb/about-edpb/members_en](https://www.edpb.europa.eu/about-edpb/about-edpb/members_en).

                ---

                ### 8. Änderungen dieser Datenschutzerklärung
                Wir behalten uns vor, diese Datenschutzerklärung anzupassen, um sie an geänderte rechtliche oder technische Rahmenbedingungen anzupassen. Die jeweils aktuelle Version finden Sie auf unserer Webseite.

                Stand: Januar 2025
        </div>

    </div> 
</template>

<script>
export default {
    name: 'Datenschutz',
    data(){
        return{

        }
    }
}

</script>
    
<style scoped>
    .wrap{
        font-family: 'Raleway-Regular';

    }
    h1{
        text-align: center;
        font-family: 'Raleway-Light';
        font-size: 36px;
        margin-bottom: 30px;
    }
    h3{
        font-size: 20px;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    p, li {
        padding-left: 30px;
        font-size: 18px;
        color: #636363;
    }

</style>